import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Details } from '../components/posts/details'

export default function PostTemplate({ data }) {
  const post = data.strapiPost
  const categories = data.allStrapiPostCategory.edges.map(({ node }) => {
    return {
      id: node.id,
      title: node.title,
      slug: node.slug,
      count: node.posts.length || 0,
    }
  })

  return (
    <Layout>
      <SEO title={post.title} description={post.content} />
      <Details
        post={post}
        categories={categories}
        currentCategory={post.category}
      />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    # Get current post details.
    strapiPost(slug: { eq: $slug }) {
      id
      title
      slug
      content
      datetime
      category {
        id
        title
        slug
      }
      image {
        name
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

    # Get all categories and their posts to generate category filter (and count).
    allStrapiPostCategory {
      edges {
        node {
          posts {
            id
          }
          id
          title
          slug
        }
      }
    }
  }
`
