import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { navigate } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

import { config, breakpoint } from '../../../config'

import { Filter } from '../../filter'
import { Title } from '../../title'
import { Container } from '../../container'
import { Content } from '../../content'
import { Share } from '../../share'
import { Label } from '../../label'
import { Box } from '../../box'
import { Intro } from '../../intro'

import arrowLeft from '../../../assets/arrow-left-lightgreen.svg'

const StyledBox = styled(Box)`
  margin: 0 -20px;

  @media ${breakpoint.tablet} {
    margin: 0;
  }
`

const Media = styled.div`
  margin-bottom: 1.5rem;
`

const Text = styled.div`
  margin-bottom: 30px;
`

const Meta = styled.div`
  margin-bottom: 22px;
  margin-top: -8px;
`

const MetaDate = styled.span`
  display: inline-block;
  margin-right: 20px;
`

const MetaCategory = styled.span`
  display: inline-block;
  font-family: 'Morandi-Bold', sans-serif;
`

const StyledLink = styled(Link)`
  color: #7ba686;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: ${config.colors.base};
  }
`

const Icon = styled.img`
  margin-right: 18px;
  display: block;
`

const StyledTitle = styled(Title)`
  margin-bottom: 0.7825em;
`

export function Details({ categories, post, currentCategory }) {
  const date = format(new Date(post.datetime), 'd. MMMM yyyy', { locale: de })

  let filterItems = categories.map((category) => {
    return {
      key: category.slug,
      label: `${category.title} (${category.count})`,
    }
  })

  filterItems.push({ key: 'ALL', label: 'Alle ansehen' })

  function handleFilterChange(item) {
    if (item.key === 'ALL') {
      navigate('/news/')
    } else {
      navigate(`/news/kategorie/${item.key}/`)
    }
  }

  return (
    <Container>
      <Content
        sidebar={
          <Filter
            label="Kategorien"
            items={filterItems}
            onChange={handleFilterChange}
            defaultActive={currentCategory ? currentCategory.slug : 'ALL'}
          />
        }
      >
        <Intro>News</Intro>
        <StyledBox>
          <Meta>
            <MetaDate>{date}</MetaDate>
            <MetaCategory>{post.category.title}</MetaCategory>
          </Meta>
          <StyledTitle size={2}>{post.title}</StyledTitle>
          {post.image && (
            <Media>
              <Img fluid={post.image.childImageSharp.fluid} fadeIn={false} />
            </Media>
          )}
          <Text><ReactMarkdown source={post.content} plugins={[breaks]} /></Text>
          <Label>Teilen</Label>
          <Share />
        </StyledBox>
        <br />
        <StyledLink to="/news">
          <Icon src={arrowLeft} alt="" /> <span>Zurück zur Übersicht</span>
        </StyledLink>
      </Content>
    </Container>
  )
}
