import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  width: auto;
  height: 38px;

  .fill-transition {
    transition: fill 0.25s ease;
  }
`

export function Linkedin({ color = 'currentColor' }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.098 36.824">
      <g style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
        <path
          d="M17.258.007c20.5.533 19.274 14.117 18.5 22.478-.333 3.569-3.55 8.4-7.042 11.147-1.723 1.354-8.349 3.4-11.3 3.176-5.621-.419-11.273-2.07-14.269-7.343C-4.347 16.286 2.024-.389 17.258.007z"
          fill={color}
          className="fill-transition"
        />
        <path
          d="M13.768 25.868h-3.539V15.215h3.539zm-1.771-12.1h-.022a2.023 2.023 0 11.022 0zm14.535 12.1h-3.541v-5.7c0-1.431-.51-2.408-1.793-2.408a1.937 1.937 0 00-1.815 1.3 2.391 2.391 0 00-.117.863v5.947h-3.542s.048-9.65 0-10.649h3.542v1.51a3.516 3.516 0 013.191-1.76c2.33 0 4.075 1.522 4.075 4.792z"
          fill="#fff"
        />
      </g>
    </Svg>
  )
}
