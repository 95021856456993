import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { config } from '../../config'
import { Facebook, Linkedin, Xing } from './icons'

const Wrapper = styled.div`
  position: relative;
  display: flex;
`

const List = styled.ul`
  margin: -4px;
  padding: 0;
  list-style: none;
  display: flex;
`

const Item = styled.li``

const Button = styled.button`
  background: none;
  display: block;
  border: 0;

  outline: none; /* TODO */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 4px;
  color: ${config.colors.base};

  &:hover {
    color: ${config.colors.primary};
  }
`

export function Share() {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const encodedUrl = encodeURI(window.location.href)
    setUrl(encodedUrl)
  }, [])

  return (
    <Wrapper>
      <List>
        <Item>
          <Button
            as="a"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            target="_blank"
          >
            <Facebook />
          </Button>
        </Item>
        <Item>
          <Button
            as="a"
            href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}`}
            target="_blank"
          >
            <Linkedin />
          </Button>
        </Item>
        <Item>
          <Button
            as="a"
            href={`https://www.xing.com/app/user?op=share;url=${url}`}
            target="_blank"
          >
            <Xing />
          </Button>
        </Item>
      </List>
    </Wrapper>
  )
}
