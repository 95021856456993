import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  width: auto;
  height: 38px;

  .fill-transition {
    transition: fill 0.25s ease;
  }
`

export function Facebook({ color = 'currentColor' }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.098 36.824">
      <g style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
        <path
          d="M17.258.007c20.5.533 19.274 14.117 18.5 22.478-.333 3.569-3.55 8.4-7.042 11.147-1.723 1.354-8.349 3.4-11.3 3.176-5.621-.419-11.273-2.07-14.269-7.343C-4.347 16.286 2.024-.389 17.258.007z"
          fill={color}
          className="fill-transition"
        />
        <path
          d="M23.372 12.239h-3.044c-.36 0-.762.473-.762 1.107v2.2h3.806v3.134h-3.806v9.408h-3.593V18.68h-3.254v-3.134h3.258v-1.841a4.52 4.52 0 014.355-4.8h3.044z"
          fill="#fff"
        />
      </g>
    </Svg>
  )
}
