import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  width: auto;
  height: 38px;

  .fill-transition {
    transition: fill 0.25s ease;
  }
`

export function Xing({ color = 'currentColor' }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.098 36.824">
      <g style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
        <path
          d="M17.258.007c20.5.533 19.274 14.117 18.5 22.478-.333 3.569-3.55 8.4-7.042 11.147-1.723 1.354-8.349 3.4-11.3 3.176-5.621-.419-11.273-2.07-14.269-7.343C-4.347 16.286 2.024-.389 17.258.007z"
          className="fill-transition"
          fill={color}
        />
        <path
          d="M12.59 22.077h-2.577a.376.376 0 01-.337-.176.394.394 0 010-.4l2.738-4.833a.013.013 0 000-.014l-1.743-3.018a.4.4 0 01-.014-.4.4.4 0 01.351-.161h2.578a.8.8 0 01.717.483l1.771 3.091-2.781 4.92a.791.791 0 01-.7.5zm12.188-11.96l-5.707 10.09a.022.022 0 000 .017l3.634 6.639a.4.4 0 010 .4.379.379 0 01-.341.165H19.79a.806.806 0 01-.72-.491l-3.662-6.719 5.735-10.172a.757.757 0 01.69-.491h2.6a.383.383 0 01.343.165.4.4 0 01-.005.4zm0 0"
          fill="#fff"
        />
      </g>
    </Svg>
  )
}
